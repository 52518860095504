.product {
    width: 100%;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product__banner {
    align-self: stretch;

    padding: 4rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url('/static/img/main_bannermobile.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}
.product__banner .banner__content {
    text-align: center;
}

.product__body {
    margin: 3rem 0;
    width: 80%;
}
.product__content {
    height: 400px;
    margin-bottom: 4rem;
    display: flex;
    border-radius: 2rem;
    overflow: hidden;
    cursor: pointer;
}
.product__img {
    width: 60%;
}
.product__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.product__text {
    width: 40%;
    padding: 4rem;
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
}
.product__name {
    font-size: 1.25rem;
    font-weight: 500;
    color: #555555;
}
.product__title {
    margin-bottom: 0.5rem;
    font-size: 1.75rem;
    font-weight: 700;
}
.product__summary {
    font-size: 1rem;
}

/* 상세 */
.product__page {
    padding-top: 4rem;
    width: 100%;
}
.product__contentImg {
    width: 100%;
}

@media screen and (min-width: 1281px) {
    .product__body {
        width: 1080px;
    }
    .product__page {
        padding-top: 5rem;
        width: 1180px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .product__content {
        height: calc(100vw / 25 * 8);
    }
    .product__text {
        padding: 3rem;
    }
    .product__title {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 745px) and (max-width: 1024px) {
    .product__content {
        height: calc(100vw / 25 * 8);
    }
    .product__text {
        padding: 2rem 1.5rem;
    }
    .product__title {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 744px) {
    .product__banner {
        padding: 2rem;
    }
    .product__body {
        padding: 0 2rem;
        width: 100%;
    }
    .product__content {
        margin-bottom: 2rem;
        height: calc(100vw / 4 * 3);
        flex-direction: column;
    }
    .product__img {
        width: 100%;
        height: 60%
    }
    .product__text {
        padding: 1.5rem;
        width: 100%;
        height: 40%;
    }
    .product__name {
        font-size: 0.75rem;
    }
    .product__title {
        font-size: 1rem;
    }

    .product__page {
        padding-top: 3rem;
    }
}