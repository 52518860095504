.post {
    width: 100%;
}
.post__page {
    padding: 0 2rem;
    width: 66%; 
}
.post__header {
    padding: 1rem;
    border-bottom: 2px solid #d1d1d1;
    font-size: 2rem;
    font-weight: 700;
}
.post .news__list {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.post .news__container {
    display: flex;
    border: 1px solid #d1d1d1;
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;
}
.post .news__img {
    width: 40%;
}
.post .news__img img {
    width: 100%;
    height: 100%;
}
.post .news__content {
    padding: 2rem;
    width: 60%;
}
.post .news__date {
    font-size: 0.75rem;
}
.post .news__title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.post__page .post__title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #d1d1d1;
}
.post .news__text {
    font-size: 1rem;
    margin-bottom: 1rem;
}
.post__page .post__text {
    font-size: 1rem;
    margin-bottom: 1rem;
}
.post .news__plus {
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 0.75rem;
}
@media screen and (min-width: 1281px) {
    .post {
        padding: 5rem 2rem;
        width: 1180px;
    }
    .post__page {
        padding-top: 5rem;
        width: 1080px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .post {
        padding: 4rem;
    }
    .post__page {
        padding-top: 4rem;
    }
}

@media screen and (min-width: 745px) and (max-width: 1024px) {
    .post {
        padding: 4rem;
    }
    .post__page {
        padding-top: 4rem;
        width: 90%;
    }
    .post .news__img {
        width: 50%;
    }
    .post .news__content {
        padding: 1.5rem;
        width: 50%;
    }
}

@media screen and (max-width: 744px) {
    .post {
        padding: 3rem 1rem;
        padding-bottom: 3rem;
    }
    .post__page {
        padding: 0 1rem;
        padding-top: 3rem;
        width: 100%;
        
    }
    .post__header {
        padding: 0.5rem 0;
        padding-bottom: 1.25rem;
        font-size: 1.5rem;
    }
    .post .news__container {
        flex-direction: column;
    }
    .post .news__img {
        width: 100%;
    }
    .post .news__img img {
        width: 100%;
    }
    .post .news__content {
        padding: 1rem;
        width: 100%;
    }
    .post .news__date {
        font-size: 0.75rem;
    }
    .post .news__title {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .post__page .post__title {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .post .news__text {
        font-size: 0.75rem;
        margin-bottom: 1rem;
    }
    .post__page .post__text {
        font-size: 0.75rem;
        margin-bottom: 1rem;
    }
}