html,
body {
  font-family: "Noto Sans KR", sans-serif;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-x: hidden; */
  position: relative;
  word-break: keep-all;
  cursor: default;
  color: #555555;
}

.navigation {
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: transparent;
    color: #a0a0a0;
}
.nav__item:hover {
    color: #3db051;
}
.nav__icons {
    justify-self: end;
    padding-right: 0.5rem;

    /* width: 36px;
    height: 36px; */

    cursor: pointer;
}
.nav__icons:hover {
    color: #3db051;
}

@media screen and (min-width: 1281px) {
    .nav {
        width: 1200px;
        height: 5rem;

        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        justify-items: center;
        align-items: center;
    }

    .nav__logo {
        justify-self: start;

        height: 3.5rem;

        cursor: pointer;
    }

    .nav__logo img {
        height: 100%;
    }                             

    .nav__menu {
        grid-column: span 4 / span 4;

        display: flex;
        column-gap: 120px;

        cursor: pointer;
    }

    .nav__item {
        font-size: 1.25rem;
        font-weight: 500;
    }
    

    
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .nav {
        padding: 0 4rem;
        width: 100%;
        height: 4rem;

        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        justify-items: center;
        align-items: center;
    }

    .nav__logo {
        justify-self: start;

        height: 44px;

        cursor: pointer;
    }

    .nav__logo img {
        height: 100%;
    }

    .nav__menu {
        grid-column: span 4 / span 4;

        display: flex;
        column-gap: 80px;

        cursor: pointer;
    }

    .nav__item {
        font-size: 1rem;
        font-weight: 500;
    }

    .nav__icons {
        justify-self: end;

        cursor: pointer;
    }
}

@media screen and (min-width: 744px) and (max-width: 1024px) {
    .nav {
        padding: 0 2rem;
        width: 100%;
        height: 4rem;

        display: flex;
        align-items: center;
    }

    .nav__logo {
        flex-grow: 1;

        height: 44px;

        cursor: pointer;
    }
    
    .nav__logo img {
        height: 100%;
    }

    .nav__menu {
        display: flex;
        column-gap: 60px;

        cursor: pointer;
    }

    .nav__item {
        font-size: 1rem;
        font-weight: 500;
    }

    .nav__icons {
        margin-left: 60px;

        cursor: pointer;
    }
}

@media screen and (max-width: 743px) {
    .nav {
        padding: 0 1rem;
        width: 100%;
        height: 3rem;

        display: flex;
        align-items: center;
    }

    .nav__logo {
        flex-grow: 1;

        height: 30px;

        cursor: pointer;
    }
    
    .nav__logo img {
        height: 100%;
    }

    .nav__menu {
        display: flex;
        column-gap: 20px;

        cursor: pointer;
    }

    .nav__item {
        font-size: 0.75rem;
        font-weight: 500;
    }

    .nav__icons {
        display: none;
        margin-left: 10px;

        cursor: pointer;
    }
}