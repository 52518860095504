.about{
    padding-top: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.about__banner {
    width: 100%;
    height: 250px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url('/static/img/about_banner.png');
    background-position: center;
    filter: grayscale(0.8)
}
.about__title {
    color: white;
    font-size: 32px;
    font-weight: 700;
}
.about__body {
    margin-top: 60px;
    width: 100%;
}

.about__menu {
    width: 100%;
    padding: 2rem;

    border: 1px solid #d1d1d1;

    position: sticky;
    top: 5rem;
    background-color: white;
    z-index: 10;
}
.about__menu ul {
    list-style: none;
    display: flex;
    justify-content: center;
}
.about__menu li {
    margin: 0 2rem;
    font-size: 16px;
    cursor: pointer;
}
.about__menu a {
    color: #555555;
}
.about__menu .active a {
    color: #3db051;
}


/* 공통 */
section {
    margin-bottom: 6rem;
}
.section__title {
    font-size: 24px;
}
/* 회사개요 */
.section1__title {
    padding-bottom: 2rem;
    border-bottom: 4px solid #737373;

    color:#555555;
}
.section1__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.sum {
    padding: 1.5rem 0;
    border-bottom: 1px solid #d1d1d1
}
.sum__title {
    margin-bottom: 0.5rem;
    font-size: 12px;
    font-weight: 500;
    color: #999999;
}

.sum__content {
    font-size: 16px;
    font-weight: 700;
    color: #474747;
}

#section2 {
    padding: 2rem;
    background-color: #ECFFEF;
    text-align: center;

}
.section2__title {
    padding-bottom: 0.5rem;
    font-weight: 700;
    color:#3db051;
}
.section2__content {
    font-size: 16px;
    color:#474747;
}


.section3__back {
    margin-bottom: 2rem;
    height: 250px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url('/static/img/about_mission.png');
    background-position: left;
    filter: grayscale(0.3);

    display: flex;
    align-items: end;
}
.section3__title {
    padding-left: 4rem;
    padding-bottom: 2rem;
    font-weight: 700;
    color: white;
}
.section3__body {
    margin: 2rem 4rem;
    margin-bottom: 60px;
}
.mission__title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
}
.mission__content {
    margin-bottom: 12px;
    font-size: 16px;
}

.section4__back {
    margin-bottom: 2rem;
    height: 250px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url('/static/img/about_strategy.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    filter: grayscale(0.3);

    display: flex;
    justify-content: end;
    align-items: end;
}
.section4__title {
    padding-right: 4rem;
    padding-bottom: 2rem;

    font-weight: 700;
    color: white;
}
.section4__body {
    margin: 2rem 4rem;

    display: flex;
    justify-content: space-between;
    gap: 2rem;

    text-align: start;
}

.strategy__content {
    margin-bottom: 12px;
    display: flex;
}
.strategy__num {
    margin-top: -0.5rem;
    font-size: 36px;
    font-weight: 700;
}
.strategy__text {
    padding-left: 2rem;
    font-size: 20px;
}


#section5 {
    display: flex;
    justify-content: center;
}
.section5__back {
    width: 80%;

    
    padding: 60px 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url('/static/img/about_history.png');
    background-repeat: no-repeat;
    background-position: top;
}
.section5__title {
    align-self: end;

    padding: 16px 0 16px 120px;
    border-left: 13px solid #555555;

    text-align: end;
    color: #555555;
}
.section5__subtitle {
    font-size: 36px;
    font-weight: 700;
}
.section5__maintitle {
    font-size: 33px;
}
.section5__body {
    width: 80%;
}

#section6 {
    width: 100%;
}
.section6__back {
    width: 100%;
    height: 220px;

    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
     url('/static/img/about_locationback.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
}
.section6__title {
    font-weight: 700;
    color: white;
}
.section6__body {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #474747
}
.section6__body img {
    padding-top: 20px;
}

@media screen and (min-width: 1281px) {
    .about__body {
        width: 1180px;
    }
    section {
        margin-bottom: 8rem;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .about {
        padding-top: 4rem;
    }
    .about__menu {
        top: 4rem;
    }
    .about__body {
        padding: 0 4rem;
    }
    .section5__back {
        padding: 40px 60px;
    }
    .section5__title {
        padding: 12px 0 12px 120px;
        border-left: 10px solid #555555;
    }
    .section5__subtitle {
        font-size: 2rem;
    }
    .section5__maintitle {
        font-size: 1.5rem;
    }
    .section5__body {
        width: 100%;
    }
}

@media screen and (min-width: 745px) and (max-width: 1024px) {
    .about {
        padding-top: 4rem;
    }
    .about__menu {
        top: 4rem;
    }
    .about__body {
        padding: 0 2rem;
    }
    .section4__body {
        margin: 2rem;
    }
    .strategy__text {
        padding-left: 1rem;
        font-size: 1rem;
    }
    .section5__back {
        padding: 20px 40px;
    }
    .section5__title {
        padding: 12px 0 12px 80px;
        border-left: 8px solid #555555;
    }
    .section5__subtitle {
        font-size: 1.5rem;
    }
    .section5__maintitle {
        font-size: 1rem;
    }
    .section5__body {
        width: 100%;
    }
}

@media screen and (max-width: 744px) {
    .about {
        padding-top: 3rem;
    }
    .about__banner {
        height: 150px;
    }
    .about__title {
        color: white;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .about__menu {
        padding: 1rem;
        top: 3rem;
    }
    .about__menu li {
        margin: 0 0.5rem;
        font-size: 0.75rem;
    }
    .about__body {
        margin-top: 2rem;
        padding: 0;
    }
    section {
        margin-bottom: 3rem;
    }
    #section1 {
        padding: 0 1rem;
    }
    .section__title {
        font-size: 1.25rem;
    }
    .section1__title {
        padding-bottom: 1rem;
    }
    .sum {
        padding: 1rem 0.5rem;
    }
    .sum__content {
        font-size: 0.75rem;
    }
    #section2 {
        padding: 2rem 0;
    }
    .section2__content {
        font-size: 0.75rem;
    }
    .section3__back {
        height: 150px;
    }
    .section3__title {
        padding-left: 2rem;
    }
    .section3__body {
        margin: 2rem 1.5rem;
    }
    .mission__title {
        font-size: 1.25rem;
    }
    .mission__content {
        font-size: 0.75rem;
    }
    .section4__back {
        height: 150px;
    }
    .section4__title {
        padding-right: 2rem;
    }
    .section4__body {
        margin: 2rem 4rem;
        flex-direction: column;
    }
    .strategy__text {
        font-size: 1rem;
    }
    .section5__back {
        padding: 1rem 0;
        width: 100%;
    }
    .section5__title {
        align-self: start;
        margin-left: 8px;
        padding: 0 0 0 12px;
        border-left: 4px solid #555555;
    }
    .section5__subtitle {
        display: none;
    }
    .section5__maintitle {
        font-size: 1.25rem;
        font-weight: 700;
    }
    .section5__body {
        width: 100%;
    }
    .section6__back {
        height: 150px;
    }
    .section6__body {
        padding: 2rem 1rem;
    }
}