/* 데스크탑 */
.contact {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact__header {
  display: flex;
}

.contact__school {
  padding: 5rem 0;
  width: 1180px;
  display: flex;
  gap: 2rem;
}
.contact__school .contact__content {
  padding: 2rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.email-me {
  margin-top: 2rem;
  padding: 2rem;
  width: 50%;
  border: 1px solid #d1d1d1;
  border-radius: 2rem;
  
}
@media screen and (min-width: 1281px) {
    .contact__logo {
      height: 72px;
    }
    .contact__title {
      padding-top: 60px;
      line-height: 55px;
  
      font-size: 38px;
      color: #474747;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title {
      padding-top: 20px;
      padding-bottom:20px;
      line-height: 55px;
  
      font-size: 28px;
      color: #3db051;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title br {
      display: none;
    }
    .contact__img {
      margin-top: 40px;
      height: 121px;
    }
    .contact__body {
      width: 758px;
    }
    .contact__header .section__title {
      padding: 40px 0;
  
      line-height: 34px;
      font-size: 24px;
      font-weight: 700;
      color: #474747;
    }
    .section__form {
      padding-top: 20px;
    }
    .section__box {
      margin-top: 20px;
      width: 100%;
    }
    .box__info {
      padding: 2rem;
      border: 1px solid #d1d1d1;
    }
    .box__info img {
      margin-bottom: 1rem;
    }
    .box__title {
      line-height: 34px;
      font-size: 18px;
      font-weight: 500;
      color: #474747;
    }
    .box__input {
      width: 100%;
      margin-top: 12px;
      padding: 14px 20px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #474747;
    }
    .box__input:focus {
      outline: solid 1px #3db051;
    }
    .box__input::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .box__textarea {
      width: 100%;
      margin-top: 20px;
      padding: 20px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #474747;
    }
    .box__textarea:focus {
      outline: solid 1px #3db051;
    }
    .box__textarea::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .section__button {
      margin: 40px 0;
      width: 126px;
      height: 44px;
      border: #3db051 solid 1px;
      border-radius: 20px;
  
      color: #3db051;
      font-size: 16px;
      font-weight: 700;
    }
    .section__button:hover {
      border: #3db051 solid 1px;
      border-radius: 20px;
      background-color: #3db051;
  
      color: white;
      font-size: 16px;
      font-weight: 700;
    }
  }
  
  /* 태블릿 */
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .contact {
      margin-top: 4rem;
    }
    .contact__logo {
      height: 41px;
    }
    .contact__title {
      padding-top: 60px;
      line-height: 34px;
  
      font-size: 24px;
      color: #474747;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title {
      padding-top: 20px;
      line-height: 32px;
  
      font-size: 22px;
      color: #3db051;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title br {
      display: none;
    }
    .contact__img {
      margin-top: 40px;
      height: 111px;
    }
    .contact__body {
      padding: 0 4rem;
      width: 100%;
    }
    .contact__header .section__title {
      padding: 40px 0;
  
      line-height: 28px;
      font-size: 20px;
      font-weight: 700;
      color: #474747;
    }
    .section__form {
      padding-top: 20px;
    }
    .section__box {
      margin-top: 20px;
      width: 100%;
    }
    .box__title {
      line-height: 34px;
      font-size: 18px;
      font-weight: 500;
      color: #474747;
    }
    .box__input {
      width: 100%;
      margin-top: 12px;
      padding: 14px 20px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #474747;
    }
    .box__input:focus {
      outline: solid 1px #3db051;
    }
    .box__input::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .box__textarea {
      width: 100%;
      margin-top: 20px;
      padding: 20px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #474747;
    }
    .box__textarea:focus {
      outline: solid 1px #3db051;
    }
    .box__textarea::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .section__button {
      margin: 40px 0;
      width: 126px;
      height: 44px;
      border: #3db051 solid 1px;
      border-radius: 20px;
  
      color: #3db051;
      font-size: 16px;
      font-weight: 700;
    }
    .section__button:hover {
      border: #3db051 solid 1px;
      border-radius: 20px;
      background-color: #3db051;
  
      color: white;
      font-size: 16px;
      font-weight: 700;
    }

    .contact__school {
      padding: 4rem;
      width: 100%;
    }
    .email-me {
      padding: 1rem;
      width: 50%;
    }
  }
  
  @media screen and (min-width: 745px) and (max-width: 1024px) {
    .contact {
      margin-top: 4rem;
    }
    .contact__logo {
      height: 41px;
    }
    .contact__title {
      padding-top: 60px;
      line-height: 34px;
  
      font-size: 24px;
      color: #474747;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title {
      padding-top: 20px;
      line-height: 32px;
  
      font-size: 22px;
      color: #3db051;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title br {
      display: none;
    }
    .contact__img {
      margin-top: 40px;
      height: 111px;
    }
    .contact__body {
      padding: 0 2rem;
      width: 100%;
    }
    .contact__header .section__title {
      padding: 40px 0;
  
      line-height: 28px;
      font-size: 20px;
      font-weight: 700;
      color: #474747;
    }
    .section__form {
      padding-top: 20px;
    }
    .section__box {
      margin-top: 20px;
      width: 100%;
    }
    .box__title {
      line-height: 34px;
      font-size: 18px;
      font-weight: 500;
      color: #474747;
    }
    .box__input {
      width: 100%;
      margin-top: 12px;
      padding: 14px 20px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #474747;
    }
    .box__input:focus {
      outline: solid 1px #3db051;
    }
    .box__input::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .box__textarea {
      width: 100%;
      margin-top: 20px;
      padding: 20px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #474747;
    }
    .box__textarea:focus {
      outline: solid 1px #3db051;
    }
    .box__textarea::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .section__button {
      margin: 40px 0;
      width: 126px;
      height: 44px;
      border: #3db051 solid 1px;
      border-radius: 20px;
  
      color: #3db051;
      font-size: 16px;
      font-weight: 700;
    }
    .section__button:hover {
      border: #3db051 solid 1px;
      border-radius: 20px;
      background-color: #3db051;
  
      color: white;
      font-size: 16px;
      font-weight: 700;
    }

    .contact__school {
      padding: 4rem 2rem;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
    .contact__school .contact__content {
      width: 100%;
    }
    .email-me {
      margin-top: 0;
      padding: 1rem;
      width: 100%;
    }
  }
  /* 모바일 */
  @media screen and (max-width: 744px) {
    .contact {
      margin-top: 3rem;
    }
    .contact__logo {
      height: 41px;
    }
    .contact__title {
      padding-top: 60px;
      line-height: 28px;
  
      font-size: 18px;
      color: #474747;
      text-align: center;
      font-weight: 500;
    }
    .contact__sub_title {
      padding-top: 20px;
      line-height: 30px;
  
      font-size: 17px;
      color: #3db051;
      text-align: center;
      font-weight: 500;
    }
    .contact__img {
      margin-top: 40px;
      height: 88px;
    }
  
    .contact__body {
      width: 100%;
      padding: 0 1rem;
    }
    .contact__header  .section__title {
      padding: 20px 0;
  
      line-height: 24px;
      font-size: 17px;
      font-weight: 700;
      color: #474747;
    }
    .section__form {
      padding-top: 8px;
    }
    .section__box {
      margin-top: 12px;
      width: 100%;
    }
    .box__title {
      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
      color: #474747;
    }
    .box__input {
      width: 100%;
      margin-top: 12px;
      padding: 11px 12px;

      border: solid 1px #d1d1d1;

  
      line-height: 20px;
      font-size: 12px;
      font-weight: 400;
      color: #474747;
    }
    .box__input:focus {
      outline: solid 1px #3db051;
    }
    .box__input::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .box__textarea {
      width: 100%;
      margin-top: 20px;
      padding: 20px 12px;

      border: solid 1px #d1d1d1;
  
      line-height: 20px;
      font-size: 12px;
      font-weight: 400;
      color: #474747;
    }
    .box__textarea:focus {
      outline: solid 1px #3db051;
    }
    .box__textarea::placeholder {
      font-weight: 300;
      color: #8c8c8c;
    }
    .section__button {
      margin: 40px 0;
      width: 114px;
      height: 40px;
      border: #3db051 solid 1px;
      border-radius: 20px;
  
      color: #3db051;
      font-size: 16px;
      font-weight: 700;
    }
    .section__button:hover {
      border: #3db051 solid 1px;
      border-radius: 20px;
      background-color: #3db051;
  
      color: white;
      font-size: 16px;
      font-weight: 700;
    }

    .contact__school {
      padding: 3rem 1rem;
      width: 100%;
      flex-direction: column;
      gap: 1rem;
    }
    .contact__school .contact__content {
      padding: 1rem;
      width: 100%;
      gap: 1rem;
    }
    .email-me {
      padding: 1rem;
      width: 100%;
    }
  }