.break {
  display: none;
}
.main__body {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

}
.main__video {
  width: 100%;
  position: absolute;
  z-index: 5;
}
.video__src {
  width: 100%;
  object-fit: cover;
}
.main__banner {
  align-self: stretch;
  height: 100vh;

  padding-top: 4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url('/static/img/main_bannermobile.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner__content {
  width: 100%;

  display: flex;
  flex-direction: column;

  color: #474747;
}
.banner__subtitle {
  font-size: 2rem;
  font-weight: 500;
}
.banner__title {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 60px;
}
.banner__text {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 40px;
}
.banner__button {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  color: #474747;
  cursor: pointer;
  border-radius: 30px;
  border: 1px solid #d1d1d1;
  align-self: start;
}
/* 서비스소개 */
.main__service {
  width: 100%;

  display: flex;
  flex-direction: column;
}
.service__header {
  align-self: center;

  width: 100%;
  height: 4rem;

  margin: 6rem 0 2rem 0;

  display: flex; 
  justify-content: start;
  align-items: center;

  font-size: 2rem;
  font-weight: 700;

  cursor: pointer;
}
.service__body {
  width: 100%;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-content: stretch;
  gap: 40px;
}
.service__content {
  width: 100%;
  
  display: flex;

  cursor: pointer;
}
.service__img {
  width: 66%;
  height: calc(1160px / 6);
  object-fit: cover;
  border-radius: 1rem;
}
.service__box {
  width: 34%;
  
  padding-left: 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  color: #555555;
}
.service__title {
  margin-bottom: 0.75rem;
  padding-bottom: 0.25rem;
  padding-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  border-bottom: 2px solid #767676
}
.service__text {
  font-size: 12px;
}

/* news */
.main__news {
  padding: 0 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.news__header {
  align-self: end;

  width: 50%;
  height: 4rem;

  margin: 6rem 0 2rem 0;
  padding: 0 2rem;

  display: flex; 
  justify-content: space-between;
  align-items: center;

  border-bottom: 2px solid #474747;


  cursor: pointer;
}
.header__title {
  font-size: 1.5rem;
  font-weight: 700;
}
.header__icon {
  width: 36px;
  height: 36px;
  stroke: #767676;
}
.main__header:hover .header__icon {
  stroke: #474747;
}

/* news swiper */
.swiper-container {
  width: 100%;
  overflow: hidden;
}
.swiper-slide {
  width: calc(100vw / 5);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* border: 1px solid #d1d1d1; */
  background-color: #fff;
  overflow: hidden;

  cursor: pointer;
}
.slide-img {
  width: 100%;
  height: calc(100vw / 10);
  border: 1px solid #d1d1d1;
  border-radius: 1rem;
  object-fit: cover;
}
.slide-content {
  width: 100%;
  padding: 1rem 0.5rem;

  display: flex;
  flex-direction: column;
  
  position: relative;
    
  color: #474747;
}
.news__date {
  font-size: 0.75rem;
}
.news__title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 16px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.news__text {
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  white-space: normal;
  line-height: 1.2em; /* 라인 높이 설정 */
  height: 2.4em;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news__icon {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.news__icon svg {
  width: 24px;
  height: 24px;
}
/* notice */
.main__notice {
  padding: 0 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.notice__header {
  width: 50%;
  height: 4rem;

  margin: 6rem 0 1rem 0;
  padding: 0 2rem;

  display: flex; 
  justify-content: space-between;
  align-items: center;

  border-bottom: 2px solid #474747;


  cursor: pointer;
}
.notice__body {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}
.notice__content {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  border-bottom: 1px solid #d1d1d1;

  cursor: pointer;
}
.notice__title {
  font-size: 1rem;
  color: #474747;
}
.notice__icon {
  flex: none;
  width: 16px;
  height: 16px;
  stroke: #737373;
}

.notice__content:hover .notice__icon {
  stroke: #474747;
}

@media screen and (min-width: 1281px) {
  .video__src {
    height: calc(100vh);
  }
  .main__banner {
    height: calc(100vh - 4rem);
  
    background-image: url('/static/img/main_banner.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .banner__content {
    width: 1180px;
  }
  .main__news {
    padding: 0;
    width: 1180px;
  }
  .main__service {
    width: 1180px;
  }
  .service__body {
    padding: 0 50px;
  }
  .main__notice {
    padding: 0;
    width: 1180px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .video__src {
    height: calc(80vh);
  }
  .main__banner {
    height: calc(80vh - 4rem);
  }
  .banner__content {
    padding: 0 8rem;
  }
  .news__header {
    width: 60%;
  }
  .main__news {
    padding: 0 4rem;
  }
  .swiper-slide {
    width: calc(100% / 4);
  }
  .slide-img {
    height: calc(100vw / 9);
  }
  .main__service {
    padding: 0 4rem;
  }
  .service__header {
    margin: 6rem 0 1rem 0;
  }
  .notice__header {
    width: 60%;
  }
  
}

@media screen and (min-width: 745px) and (max-width: 1024px) {
  .video__src {
    height: calc(40vh);
  }
  .main__banner {
    height: calc(40vh);
  }
  .banner__content {
    padding: 0 5rem;
  }
  .banner__subtitle {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .banner__title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  .banner__text {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  .banner__button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    color: #474747;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid #d1d1d1;
    align-self: start;
  }

  .main__news {
    padding: 0 4rem;
  }
  .news__header {
    width: 70%;
    margin: 4rem 0 2rem 0;
  }
  .swiper-slide {
    width: calc(100% / 3);
  }
  .slide-img {
    height: calc(100vw / 7);
  }
  .service__body {
    align-self: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-content: center;
    gap: 30px;
  }
  
  .main__service {
    padding: 0 4rem;
  }
  .service__header {
    margin: 4rem 0 1rem 0;
    font-size: 1.75rem;
  }
  .service__img {
    width: 55%;
    height: calc((100vw - 8rem) * 0.3);
  }
  .service__box {
    width: 45%; 
    padding-left: 2rem;
    padding-top: 0.5rem
  }
  .notice__header {
    width: 70%;
  }
}

@media screen and (max-width: 744px) {
  .break {
    display: block;
  }
  .video__src {
    height: calc(40vh);
  }
  .main__banner {
    height: calc(40vh);
  }
  .banner__content {
    padding: 0 2rem;
  }
  .banner__subtitle {
    font-size: 1rem;
    font-weight: 500;
  }
  .banner__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .banner__text {
    font-size: 0.75rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  .banner__button {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    color: #474747;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid #d1d1d1;
    align-self: start;
  }
  .main__news {
    padding: 0 1.5rem;
  }
  .news__header {
    margin: 2rem 0 1rem 0;
    padding: 0 0.5rem;
    width: 85%;
    height: 3rem;
  }
  .header__title {
    font-size: 1.25rem;
  }
  .swiper-slide {
    width: 80%;
  }
  .slide-img {
    height: calc(100vw / 3);
  }
  .service__body {
    align-self: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-content: center;
    gap: 20px;
  }

  .main__service {
    padding: 0 1.5rem;
  }
  .service__header {
    margin: 2rem 0 1rem 0;
    height: 3rem;
    font-size: 1.5rem;
  }
  .service__img {
    width: 55%;
    height: calc((100vw - 3rem) / 3);
  }
  .service__box {
    width: 45%; 
    padding-left: 1.5rem;
    padding-top: 0.5rem
  }
  .main__notice {
    padding: 0 1.5rem;
  }
  .notice__header {
    margin: 2rem 0 0 0;
    padding: 0 0.5rem;
    width: 85%;
    height: 3rem;
  }
  .notice__title {
    font-size: 0.75rem;
  }
  .notice__content {
    padding: 1rem 0.5rem;
  }
}